import Vue from 'vue';
import * as Sentry from '@sentry/vue';

const excludedMode = 'development';
export function init({ version, namespace }) {
    if (process.env.NODE_ENV !== excludedMode) {
        Sentry.init({
            Vue,
            dsn: 'https://55b352c0a02941b2afdedd04911ba838@o507582.ingest.us.sentry.io/5599675',
            maxBreadcrumbs: 50,
            debug: false,
            release: version,
            autoSessionTracking: true,
            integrations: [
                Sentry.replayIntegration({
                    maskAllText: false,
                    maskAllInputs: false,
                    networkDetailAllowUrls: ['https://api.blixem.app/1/*']
                }),
            ],
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 0,

            // We recommend adjusting this value in production, or using tracesSampler
            // for finer control
            tracesSampleRate: 1.0,
            logErrors: true,
            ignoreErrors: [
                'ChunkLoadError',
                'Promise.allSettled is not a function',
                'Redirected when going from',
            ],
        });
        Sentry.setTag('namespace', namespace);
        Sentry.setContext('app', { app_build: namespace });
    }
}

export function reportError(error, context = {}, type = 'error') {
    if (process.env.NODE_ENV !== excludedMode) {
        if (type === 'error') {
            Sentry.captureException(error, context);
        } else if (type === 'message') {
            Sentry.captureMessage(error, context);
        }
    } else {
        console.error(`Report ${type}:`, error, context);
    }
}

export function reportMessage(msg, context = {}) {
    reportError(msg, context, 'message');
}

export function updateMetadata({ user }) {
    if (process.env.NODE_ENV !== excludedMode) {
        const {
            id, email, name: username, lang,
        } = user;
        Sentry.setUser({
            id,
            email,
            username,
            lang,
        });

        if (email === 'koenraadkees@gmail.com') {
            const replay = Sentry.getReplay();
            navigation.addEventListener("navigate", (event) => {
                const url = new URL(event.destination.url);
                if (url.href.includes('rental.crlu.nl/bookings/item/')) {
                    replay.start();
                } else {
                    replay.stop();
                }
            });
        }
    }
}
