import { api } from '@/assets/js/api';
import { generateID } from '@/services/helpers-ts';
import { reportError } from '@/services/errorhandling/';

/**
 * Get nested checklist from api
 * @returns {array} - checklist in groups-format.
 *
 * @param {array} ids - array with item ids to get the checklist for
 * @param {string} table - table where items are located
 * @param {boolean} as_checklistitem - if item is added as a main product (false) or in an existing checklist (true)
 */
export function getChecklist({ ids = [], table, as_checklistitem }) {
    return new Promise((resolve, reject) => {
        if (ids.length) {
            api.get({
                name: `get_checklists_${ids.join(',')}`,
                endpoint: `items/${table}/${ids.join(',')}/checklist/`,
                data: { nested: true, as_checklistitem },
                loading: true,
            }).then((r) => {
                if (r.data.status === 'success' && r.data.results) {
                    resolve(r.data.results);
                } else {
                    throw new Error('Unexpected results');
                }
            }).catch((err) => {
                reportError(err, {
                    extra: {
                        msg: 'invalid checklistresponse',
                    },
                    fingerprint: 'invalid-checklist',
                    tags: { section: 'invalid-checklist' },
                });
                reject(err);
            });
        } else {
            reject();
        }
    });
}

/**
 * Convert single item to checklistitem
 * @returns { object } - single checklist item.
 *
 * @param {object} item - the item
 * @param {number} choiceitemID - store choiceitem id in checklistitem, if this is added because of a choiceitem
 * @param {number} parentAmount - multiply the items amount if multiple items are being added at once.
 */
export function convertProductToChecklist(item, choiceitemID = false, parentAmount = 1) {
    let amount_value = (item.amount || 1) * parentAmount;
    if (typeof amount_value !== 'number') { amount_value = 1; }
    const amount = Math.max(1, amount_value);
    const id = Array(amount).fill().map(() => generateID());
    let name = item.name;
    let productId = item.itemid || item.id;
    if (typeof item.product === 'string') { name = item.product; } else if (typeof item.product === 'object' && item.product.name) { name = item.product.name; productId = item.product.id; }
    return {
        id,
        amount,
        name,
        itemid: productId,
        serialid: item.serialid,
        is_purchaseitem: item.is_purchaseitem,
        choiceitemid: choiceitemID,
        items: [],
        checkout: [],
        checkin: [],
    };
}

/**
 * Convert list of checklistitems to proper checklist format, loop through children if any.
 * @returns {array} - checklist
 *
 * @param {array} list - checklist in groups-structure
 * @param {number} choiceitemID - store choiceitem id in checklistitem, if this is added because of a choiceitem
 * @param {number} amount - amount of main item.
 */
export function convertChecklist(list, choiceitemID = false, amount) {
    const checklistReducer = (acc, cur) => {
        const data = convertProductToChecklist(cur, choiceitemID, amount);
        if (cur.items && cur.items.length) {
            data.items = cur.items.reduce(checklistReducer, []);
        }
        acc.push(data);
        return acc;
    };

    return (!Array.isArray(list) || !list.length) ? [] : list.reduce(checklistReducer, []);
}

export async function createChecklistFromProduct({ product = {}, item, settings = {} } = {}) {
    let checklist = [];

    if (product.id) {
        // Checklistitem for main product
        checklist = convertProductToChecklist(item || product, false);

        // Child-checklists
        if (product.checklist) {
            // Get checklistitems for item from api
            const checklistGroups = await getChecklist({ ids: [checklist.itemid], table: settings.table || 'items', as_checklistitem: typeof settings.as_checklistitem === 'boolean' ? settings.as_checklistitem : false });
            if (checklistGroups) {
                const checklistItems = convertChecklist(checklistGroups, false, checklist.amount);

                // Convert main item to checklistitem
                checklist.items = checklistItems;
            }
        }
    }
    return checklist;
}

/**
 * get and set checklist when an item is added to a booking.
 * @returns updated data in promise
 *
 * @param {object} data - Object containing `item` (changed values for the single line), and `context`, containing `hookContext` from fields.vue
 * used as a fields-hook
 */
export async function setChecklistItems(data) {
    const settings = data.context.segment?.options?.checklists || {};
    if (settings.key) {
        const checklist = await createChecklistFromProduct({
            product: data.context.option,
            item: { ...data.context.item, ...data.item },
            settings,
        });
        data.item[settings.key] = (Array.isArray(checklist) && checklist.length === 0) ? [] : [checklist];
    }
    return data;
}

export default setChecklistItems;
